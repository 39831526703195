import React, { useEffect, useState } from "react";
import ChipButton from "../../components/ChipButton";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_SMM_PACK,
  GET_USERS_SMM_PACK_RELATIONSHIPS,
  REMOVE_SMM_PACK,
} from "../../graphql/smmPack";
import SmmPackItem from "../../components/SmmPackItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { removeActivePack, setActivePack } from "../../store/packSlice";
import {
  defaultPost,
  removeAllPosts,
  removePost,
  setLastPost,
} from "../../store/postSlice";
import { useNavigate } from "react-router-dom";
import { clearUserData } from "../../store/userSlice";
import { LoginButton } from "@telegram-auth/react";
import { ReactComponent as SignOutIcon } from "../../assets/sign-out.svg";
import Text from "../../components/Text";

const SideBar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activePack = useSelector((state: RootState) => state.pack.activePack);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const lastPosts = useSelector((state: RootState) => state.post.lastPosts);

  const [shouldBeEditing, setShouldBeEditing] = useState(false);

  const [createSmmPack] = useMutation(CREATE_SMM_PACK);
  const [removeSmmPack] = useMutation(REMOVE_SMM_PACK);
  const { loading, error, data, refetch } = useQuery(
    GET_USERS_SMM_PACK_RELATIONSHIPS,
    {
      variables: { relations: true },
    }
  );

  const handleSetActive = (id: string) => {
    const {
      smm_pack: { name },
    } = data.usersSmmPackRelationships.find(
      (relationship: any) => relationship.smm_pack.id === id
    );
    if (activePack.id === id) return;
    dispatch(setActivePack({ id, name }));
  };

  const handleRemove = async (id: string) => {
    try {
      const { data: removedPacks } = await removeSmmPack({ variables: { id } });
      if (removedPacks.removeSmmPack === 1) {
        dispatch(removePost(id));
        if (id === activePack.id) {
          const remainingPacks = data.usersSmmPackRelationships.filter(
            (pack: any) => {
              return pack.smm_pack.id !== id;
            }
          );
          const firstRemainingPack = remainingPacks[0];
          if (firstRemainingPack) {
            dispatch(
              setActivePack({
                id: firstRemainingPack.smm_pack.id,
                name: firstRemainingPack.smm_pack.name,
              })
            );
          } else {
            dispatch(
              setActivePack({
                id: null,
                name: null,
              })
            );
          }
        }
        await refetch();
      } else {
        alert("Failed to remove SMM pack. Please try again.");
        refetch();
      }
    } catch (err) {
      console.error("Error removing SMM pack:", err);
    }
  };

  const handleCreateSmmPack = async () => {
    try {
      const response = await createSmmPack({
        variables: {
          createSmmPackInput: {},
        },
      });
      const newPack = response.data.createSmmPack;
      dispatch(
        setActivePack({
          id: newPack.id,
          name: newPack.name,
        })
      );
      dispatch(setLastPost({ ...defaultPost, smm_pack_id: newPack.id }));
      refetch();
      setShouldBeEditing(true);
    } catch (err) {
      alert("An error occurred while trying to create the SMM pack.");
    }
  };

  const handleUpdateSmmPack = () => refetch();

  useEffect(() => {
    if (data && typeof data.usersSmmPackRelationships == "object") {
      // check if lastPosts contains all data from data.usersSmmPackRelationships else create new post
      const lastPostsKeys = Object.keys(lastPosts);
      const usersSmmPackRelationships = data.usersSmmPackRelationships;
      usersSmmPackRelationships.forEach((relationship: any) => {
        if (!lastPostsKeys.includes(relationship.smm_pack.id)) {
          dispatch(
            setLastPost({
              ...defaultPost,
              smm_pack_id: relationship.smm_pack.id,
            })
          );
        }
      });
      if (!activePack.id) {
        const firstPack = data.usersSmmPackRelationships[0];
        if (firstPack) {
          dispatch(
            setActivePack({
              id: firstPack.smm_pack.id,
              name: firstPack.smm_pack.name,
            })
          );
        }
      } else {
        const pack = data.usersSmmPackRelationships.find(
          (pack: any) => pack.smm_pack.id === activePack.id
        );
        if (!pack) return;
        dispatch(
          setActivePack({
            id: activePack.id,
            name: pack.smm_pack.name,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading)
    return (
      <div className="flex flex-col w-[260px] h-screen bg-black-sideBar relative">
        <Text className="sidebar-loading">Loading...</Text>
      </div>
    );
  if (error) {
    dispatch(clearUserData());
    navigate("/sign-up");
    return <p>Error: {error.message}</p>;
  }

  const handleSignOut = () => {
    dispatch(clearUserData());
    dispatch(removeActivePack());
    dispatch(removeAllPosts());
    navigate("/sign-up");
  };

  return (
    <div
      className={"flex flex-col w-[260px] h-screen bg-black-sideBar relative"}
    >
      <div className="flex justify-end items-center pt-10 pr-5 pb-0 pl-5">
        <ChipButton label="Add New" onClick={handleCreateSmmPack} />
      </div>
      <div className="p-6 px-5">
        {data.usersSmmPackRelationships.map((relationship: any) => (
          <SmmPackItem
            key={relationship.smm_pack.id}
            id={relationship.smm_pack.id}
            name={relationship.smm_pack.name}
            isActive={activePack.id === relationship.smm_pack.id}
            onSetActive={handleSetActive}
            onUpdate={handleUpdateSmmPack}
            onRemove={handleRemove}
            isEditing={shouldBeEditing}
          />
        ))}
      </div>
      <div className="box-border absolute bottom-5 w-full p-2.5 border-t border-white-default text-white">
        {!currentUser.linkedinId && (
          <a
            className="text-lg font-semibold text-grey-default cursor-pointer"
            href={`${process.env.REACT_APP_BACK_URL}/auth/linkedin/login?token=${currentUser.accessToken}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Login to LinkedIn
          </a>
        )}
        {/* // todo instead of checking for telegram in the localStorage, receive an update from the server */}
        {currentUser.telegramId == null && (
          <LoginButton
            botUsername="telaigram_bot"
            authCallbackUrl={`${process.env.REACT_APP_BACK_URL}/auth/telegram/callback?user_id=${currentUser.userId}`}
            buttonSize="large"
            showAvatar={false}
            lang="en"
            cornerRadius={6}
          />
        )}
        <div className="flex justify-between">
          <Text className="text-white-default">{currentUser.fullName}</Text>
          <SignOutIcon className="cursor-pointer" onClick={handleSignOut} />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
